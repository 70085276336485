<template>
    <div class="row mt-2">
		<label class="col-4 col-form-label" for="horse_robe">{{ $t('horse.robe') }}</label>	
        <div class="col-8">
            <b-input-group >
                <e-select
                    id="robe_code"
                    v-model="selected_robe"
                    track-by="robe_code"
                    label="robe_display"
                    :placeholder="$t('horse.robe_cheval')"
                    :options="horse_robes"
                    :searchable="true"
                    :allow-empty="false"
                    :loading="is_working"
                    :show-labels="false"
                    :disabled="!editable"
                    @input="onChange"
                />
            </b-input-group>
        </div>
    </div>

</template>

<script type="text/javascript">
    import Config from "@/mixins/Config.js";
    import HorseMixin from "@/mixins/Horse.js";

    export default {
        mixins: [Config, HorseMixin],
        props: [
            'horse_robe_code',
            'editable'
        ],
        data () {
            return {
                horse_robes: [],
                selected_robe: null,
				is_working: true
            }
        },
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
                this.horse_robes = await this.getRobes()
				this.checkSelect()
				this.is_working = false
            },
            checkSelect() {
				this.selected_robe = this.horse_robes.find(hr => hr.robe_code == this.horse_robe_code)
            },
			onChange(obj) {
				this.$emit('change', obj.robe_code)
			}
        },
        watch: {
            horse_robe_code(){
				this.checkSelect()
			}
        }
    }
</script>
